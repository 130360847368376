import axios from 'axios'

import legacyRoleManagement from './legacy-role-management'
import portalFeatures from './portal-features'
import powerBi from './power-bi'

export default {
  namespaced: true,
  state: {

  },
  actions: {
    async loadPrerequisiteData ({commit}) {
      const [
        {data: permissions}
      ] = await Promise.all([
        axios.get('/api/v2/admin/permissions')
      ])

      commit('setUserPermissions', permissions, {root: true})
    }
  },
  modules: {
    legacyRoleManagement,
    portalFeatures,
    powerBi
  }
}
