import {smarterGet, smarterPut} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    roles: []
  },
  mutations: {
    ...makeSetters([
      'roles'
    ])
  },
  actions: {
    loadAllRoles ({commit}) {
      return smarterGet('/api/v2/admin/roles/all', {
        id: 'admin.roles.load',
        onResult (roles) {
          commit('setRoles', roles)
        }
      })
    },
    async saveRole ({state, dispatch}, role) {
      await smarterPut(`/api/v2/admin/roles/${encodeURIComponent(role.name)}`, role, {
        id: 'admin.roles.save'
      })

      await dispatch('loadAllRoles')
    }
  }
}
