import axios from 'axios'

import {smarterGet} from '@helpers/vuex/data-loading'
import {makeSetters} from '@helpers/vuex/mutations'

export default {
  namespaced: true,
  state: {
    features: [],
    // new feature
    name: null,
    experimental: false
  },
  mutations: {
    ...makeSetters([
      'name',
      'features',
      'experimental'
    ]),
    addNewFeature (state) {
      const {name, experimental} = state

      state.features.push({
        name,
        experimental,
        state: 'enabled'
      })

      state.name = null
      state.experimental = false
    },
    setFeatureState (state, {name, newState}) {
      const feature = state.features.find(x => x.name === name)
      feature.state = newState
    },
    removeFeature (state, {name}) {
      state.features = state.features.filter(x => x.name !== name)
    }
  },
  actions: {
    async createFeature ({state, commit}) {
      const {name, experimental} = state

      await axios.post(`/api/v2/admin/portal-features/${name}`, experimental)
      commit('addNewFeature')
    },
    getFeatures ({commit}) {
      return smarterGet('/api/v2/admin/portal-features/', {
        id: 'admin.portalFeatures.load',
        sharedCache: true,
        onResult (data) {
          commit('setFeatures', data)
        }
      })
    },
    async removeFeature ({commit}, {name}) {
      await axios.delete(`/api/v2/admin/portal-features/${name}`)

      commit('removeFeature', {name})
    },
    async setFeatureState ({state, commit}, {name, newState}) {
      const oldState = state.features.find(x => x.name === name)

      commit('setFeatureState', {name, newState})

      try {
        await axios.put(`/api/v2/admin/portal-features/${name}/${newState}`)
      } catch (error) {
        commit('setFeatureState', {name, newState: oldState})
        throw error
      }
    }
  }
}
