import axios from 'axios'
import {partition, uniq} from 'lodash'

const api = axios.create({
  baseURL: '/api/v2/admin/power-bi'
})

const nukeReport = ({id, workspaceId, name, type}) => ({
  id,
  workspaceId,
  name,
  type,
  imported: false
})

export default {
  namespaced: true,
  state: {
    workspaces: [],
    reports: []
  },
  getters: {
    importedWorkspaces (state) {
      return state.workspaces.filter(x => x.roleNameSuffix)
    },
    activeWorkspaces (state) {
      return state.workspaces
        .filter(x => x.state === 'enabled' || x.state === 'roleRequired')
        .map(({id, state}) => ({id, roleRequired: state === 'roleRequired'}))
    },
    existingReportRoles (state) {
      return uniq(state.reports.map(x => x.roleNameSuffix).filter(x => x))
    }
  },
  mutations: {
    initialize (state, {workspaces, reports, existingReportRoles}) {
      Object.assign(state, {workspaces, reports, existingReportRoles})
    },
    setWorkspaceState (state, {id, state: workspaceState}) {
      const workspace = state.workspaces.find(x => x.id === id)

      workspace.state = workspaceState
    },
    importWorkspace (state, {id, roleNameSuffix}) {
      const workspace = state.workspaces.find(x => x.id === id)

      workspace.roleNameSuffix = roleNameSuffix
    },
    removeWorkspace (state, {id}) {
      const workspace = state.workspaces.find(x => x.id === id)

      workspace.roleNameSuffix = null

      const [affectedReports, otherReports] = partition(state.reports, {workspaceId: id})
      state.reports = otherReports.concat(affectedReports.map(nukeReport))
    },
    restoreReports (state, {workspaceId, reports}) {
      const otherReports = state.reports.filter(x => x.workspaceId === workspaceId)
      state.reports = otherReports.concat(reports)
    },
    updateReport (state, report) {
      const i = state.reports.findIndex(x => x.id === report.id)
      state.reports.splice(i, 1, report)
    },
    removeReport (state, {id}) {
      const i = state.reports.findIndex(x => x.id === id)
      const nukedReport = nukeReport(state.reports[i])
      state.reports.splice(i, 1, nukedReport)
    }
  },
  actions: {
    async load ({commit}) {
      const [
        {data: workspaces},
        {data: reports}
      ] = await Promise.all([
        api.get('workspaces'),
        api.get('reports')
      ])

      commit('initialize', {workspaces, reports})
    },
    async disableWorkspace ({state, commit}, {id}) {
      const {state: previousState} = state.workspaces.find(x => x.id === id)

      commit('setWorkspaceState', {id, state: 'disabled'})

      try {
        await api.delete(`workspaces/active/${id}`)
      } catch (error) {
        commit('setWorkspaceState', {id, state: previousState})
        throw error
      }
    },
    async enableWorkspace ({state, commit}, {id, roleRequired}) {
      const {state: previousState} = state.workspaces.find(x => x.id === id)

      commit('setWorkspaceState', {id, state: roleRequired ? 'roleRequired' : 'enabled'})

      try {
        await api.put(`workspaces/active/${id}`, {roleRequired})
      } catch (error) {
        commit('setWorkspaceState', {id, state: previousState})
        throw error
      }
    },
    async importWorkspace ({commit}, {id, roleNameSuffix}) {
      commit('importWorkspace', {id, roleNameSuffix})

      try {
        await api.put(`workspaces/${id}`, {roleNameSuffix})
      } catch (error) {
        commit('removeWorkspace', {id})
        throw error
      }
    },
    async removeWorkspace ({state, commit}, {id}) {
      const {roleNameSuffix} = state.workspaces.find(x => x.id === id)
      const reports = state.reports.filter(x => x.workspaceId === id)

      commit('removeWorkspace', {id})

      try {
        await api.delete(`workspaces/${id}`)
      } catch (error) {
        commit('importWorkspace', {id, roleNameSuffix})
        commit('restoreReports', {workspaceId: id, reports})
        throw error
      }
    },
    async updateReport ({state, commit}, report) {
      const previousReport = state.reports.find(x => x.id === report.id)

      commit('updateReport', report)

      try {
        await api.put(`/reports/${report.id}`, report)
      } catch (error) {
        commit('updateReport', previousReport)
        throw error
      }
    },
    async removeReport ({state, commit}, {id}) {
      const report = state.reports.find(x => x.id === id)

      commit('removeReport', {id})

      try {
        await api.delete(`/reports/${id}`)
      } catch (error) {
        commit('updateReport', report)
        throw error
      }
    }
  }
}
